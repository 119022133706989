@import "../../styles/vars.scss";
@import "../../styles/functions.scss";

.expenses-summary {
  width: 100%;
  background-color: $white;
  border-radius: rem(20);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: rem(300);

  .month,
  .spent {
    font-weight: 600;
  }
}
