@import "../../styles/vars.scss";
@import "../../styles/functions.scss";

.set-budget {
  max-width: rem(500);
  margin: auto;
  background: $white;
  height: calc(100vh - 92.94px);
  padding: rem(20);

  &-illustration {
    margin: auto;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .back {
    position: absolute;
    top: 30px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 150px;
    padding: rem(5);

    &:hover {
      background-color: $brand;
      transition: 0.5s all ease;

      svg {
        stroke: white;
        color: white;
      }
    }
  }
}
