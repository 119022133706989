@import "../../styles/vars.scss";
@import "../../styles/functions.scss";

.manage-account-options {
  .passwordOption {
    &.googleId {
      pointer-events: none;
      opacity: 0.4;
    }
  }
  .account-option {
    padding: rem(10);
    border-bottom: 1px solid $light-gray;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:hover {
      cursor: pointer;
      background-color: $light-gray;
    }
  }
}
