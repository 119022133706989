* {
  box-sizing: border-box;
}

html,
body {
  scroll-behavior: smooth;
  font-family: "Red Hat Display", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  margin: 0;
  padding: 0;
  font-size: 16px;
  height: 100%;
  color: #616e80;
  width: 100%;
}

a {
  text-decoration: none;
  color: #616e80;
}

button {
  background-color: transparent;
  border: none;
  color: #616e80;
}

.bold {
  font-weight: bold;
}
