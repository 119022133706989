@import "../../styles/vars.scss";
@import "../../styles/functions.scss";

.homepage {
  max-width: rem(500);
  margin: auto;
  background: $light-gray;
  padding: rem(20);
  min-height: calc(100vh - 105px);

  .search-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-content: center;
    margin-top: rem(20);
  }
}

.tile {
  background-color: $white;
  border-radius: rem(20);
  text-align: left;
  overflow-y: auto;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  margin: rem(20 0 100 0);
}
