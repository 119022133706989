@import '../../styles/vars.scss';
@import '../../styles/functions.scss';

.sign-in-and-sign-up {
    max-width: rem(500);
    margin: auto;
    background: $white;
    height: calc(100vh - 92.94px);
    padding: rem(20);

    .c-container-signUp {
        padding: 10px;
        text-align: center;

        .brand-text-color {
            color: $brand;
            cursor: pointer;
        }
    }
}