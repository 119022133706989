@import "../../styles/vars.scss";
@import "../../styles/functions.scss";
.additionalIncomes {
  max-width: rem(500);
  margin: auto;
  background: $light-gray;
  height: calc(100vh - 92.94px);
  padding: rem(20);

  &-add {
    width: rem(70);
    height: rem(70);
    background-color: $brand;
    border-radius: 50%;
    position: fixed;
    bottom: rem(16);
    right: rem(16);
    display: flex;
    justify-content: center;
    align-items: center;
    color: $white;
  }

  &-summary {
    width: 100%;
    background-color: $white;
    border-radius: rem(20);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: rem(200);

    .total-income {
      font-weight: 600;
      color: $brand;
      margin: 0;
    }

    .income-month {
      margin: 0;
    }

    .calculation {
      display: flex;
      justify-content: space-between;
      height: rem(50);
      align-items: center;
      margin-top: rem(16);

      .number {
        padding: rem(5 10);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        border: 1px solid $light-gray;
        font-weight: bold;
      }

      .operator {
        padding: rem(5);
      }

      .meta {
        text-align: center;
        margin: 0;
        font-size: rem(10);
        font-weight: normal;
        span {
          font-size: 16px;
        }
      }
    }
  }
}
