@import "../../styles/vars.scss";
@import "../../styles/functions.scss";

.bottom-nav {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: rem(20);
  max-width: rem(500);
  background-color: $white;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  border-radius: rem(20 20 0 0);
  display: flex;
  margin: auto;
  z-index: 2;

  .nav-item {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    padding: 10px 15px;
    cursor: pointer;
    transition: all 0.2s ease-out;

    &.active {
      flex-grow: 2;
      border-radius: 30px;
      background: #eee;
      justify-content: flex-start;
    }
  }

  .nav-text {
    font-size: 20px;
    margin: auto;
    text-align: center;
    font-weight: 500;
    display: none;
    transition: all 0.3s ease;
  }

  .nav-item.active > .nav-text {
    display: inline-block;
  }

  .nav-item.active > .material-icons {
    color: $brand;
  }

  .material-icons ~ .nav-text {
    color: $brand;
  }
}
