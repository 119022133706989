@import "../../styles/vars.scss";
@import "../../styles/functions.scss";

.c-header {
  padding: rem(10 20);
  width: 100%;
  z-index: 9999999;
  max-width: rem(500);
  margin: auto;
  background-color: $white;

  &_title {
    text-align: center;
    .title-lower {
      display: block;
      color: $brand;
      font-size: rem(25);
      font-weight: 600;
    }

    h1 {
      font-weight: normal;
      font-size: rem(20);
    }
  }

  &-menu {
    position: absolute;
    right: 0;
    cursor: pointer;
  }

  &_content {
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center;
  }

  &_settings {
    display: flex;
    justify-content: flex-end;
    cursor: pointer;

    img {
      width: rem(25);
      height: rem(25);
      background-color: $brand;
      border-radius: rem(30);
    }
  }

  .user-icon {
    img {
      width: rem(25);
      height: rem(25);
      border-radius: 60%;
    }
  }

  .burger {
    display: inline-block;
    flex-grow: 1;
    cursor: pointer;
    div {
      background-color: $text;
      border-radius: 2px;
      width: 20px;
      height: 2px;
      margin: 5px;
      transition: all 0.3s ease;
    }
  }
}
