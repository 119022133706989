@import '../../styles/vars.scss';
@import '../../styles/functions.scss';
.custom-button {
  min-width: 165px;
  width: 100%;
  height: 50px;
  letter-spacing: 0.5px;
  line-height: 50px;
  padding: 0 35px 0 35px;
  font-size: 15px;
  background-color: $brand;
  color: white;
  font-weight: bolder;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  border-radius: rem(8);

  &:hover {
    &:hover {
      background:$brand-darker;
      transition: all 0.2s ease;
  }
  }

  &.google-sign-in {
    display: flex;
    align-items: center;
    background-color: transparent;
    border: 1px solid $text;

    &:hover {
      background-color: #357ae8;
      border: none;
    }
  }

  &.inverted {
    background-color: white;
    color: $brand;
    border: 1px solid $brand;
    margin-top: rem(20);

    &:hover {
      background-color: $brand;
      color: $white;
      border: none;
    }
  }
}
