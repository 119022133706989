@import "../../styles/vars.scss";
@import "../../styles/functions.scss";

.search-bar {
  width: 100%;
  padding: rem(10);
  border-radius: rem(50);
  background-color: $white;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);

  .group {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
  }

  .form-input {
    margin: 0;
    background-color: transparent;
    border-bottom: none;
  }
}
